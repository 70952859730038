import {
  Account,
  Connection,
  PublicKey,
  LAMPORTS_PER_SOL,
  SystemProgram,
  TransactionInstruction,
  Transaction,
  sendAndConfirmTransaction,
} from '@solana/web3.js';

import BN from "bn.js";

import * as anchor from '@project-serum/anchor';

import { establishConnection, getTokenAccountForMintAddress, fetchAccount, fetchAccountOffer, getTokenAccountForTokenAndOwner } from "@/libs/solanaConnection";
import { anchor_init, get_pda, prepare_ata } from "@/libs/solanaProgram";
import { signAndSendTransaction, signAndSendMultipleTransactions } from "@/libs/wallet";

import { TOKEN_PROGRAM_ID, Token, createTransferCheckedInstruction  } from "@solana/spl-token";

import _idl from "@/IDL.json";
const idl = _idl;

// Address of the deployed program.
const program_id = new PublicKey('A7B5huUgAJmn5Ud1GK1ALVx4GqGiThvm9mupvKvEu9J7');

let connection = null;


import axios from 'axios';
import bs58 from "bs58";

const VAULT_ACCOUNT_PREFIX = 'aco_v13_token2LpkUGCdz2';
const VAULT_AUTHORITY_PREFIX = 'au_v13_token2LpkUGCdz2';


let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

var program;

function get_seeds(mint, project_id) {
	
	if(project_id > 10)
		return [Buffer.from(VAULT_ACCOUNT_PREFIX), mint.toBuffer(), [project_id]];
	
	return [Buffer.from(VAULT_ACCOUNT_PREFIX), mint.toBuffer()];
}

function get_seeds_authority(mint, project_id) {
	
	if(project_id > 10)
		return [Buffer.from(VAULT_AUTHORITY_PREFIX), mint.toBuffer(), [project_id]];
	
	return [Buffer.from(VAULT_AUTHORITY_PREFIX), mint.toBuffer()];
}

/**
 * 
 * Create vault
 * 
 */
export async function initialize_vault(wallet_provider, public_key, mint, project_id) {
	
	if(!connection)
		connection = await establishConnection();
	
	program = anchor_init(connection, wallet_provider, program_id, idl);
	
	mint = new PublicKey(mint);
	public_key = new PublicKey(public_key);
	
	const vault_account_pda = await get_pda(connection, program_id, get_seeds(mint, project_id));
	
	const transaction = await program.transaction.initializevault(
		new anchor.BN(project_id),
		{
			accounts: {
				mint: mint,
				owner: public_key,
				
				vaultAccount: vault_account_pda,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			// instructions: [],
			// signers: [wallet_provider, escrow],
		}
	);
	
	transaction.feePayer = new PublicKey(public_key);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	// transaction.sign(escrow);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('signature', signature);
	console.log('vault_account_pda', vault_account_pda.toString());
	
	return [signature, vault_account_pda.toString()];
}

/**
 * 
 * Send tokens in the vault
 * 
 */
export async function send_tokens(wallet_provider, public_key, mint, vault_account, tokens, decimals) {
	
	if(!connection)
		connection = await establishConnection();
	
	var transaction = new Transaction();
	
	const token_account_wallet = await getTokenAccountForTokenAndOwner(mint.toString(), public_key.toString());
	
	var power = Math.pow(10, decimals);
	
	tokens *= power;
	
	var instruction = Token.createTransferCheckedInstruction(
		new PublicKey(TOKEN_PROGRAM_ID), // Token program id
		new PublicKey(token_account_wallet), // from
		new PublicKey(mint), // mint
		new PublicKey(vault_account), // to
		new PublicKey(public_key), // from's owner
		[],
		tokens, // amount
		decimals // decimals
    );
	
	
	transaction.add(instruction);
	
	transaction.feePayer = new PublicKey(public_key);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	// transaction.sign(escrow);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('signature', signature);
	
	return signature;
}

/**
 * 
 * Create a new collection
 * 
 */
export async function create_new_collection(wallet_provider, public_key, collection_id, reward, decimals, project_id) {
	
	if(!connection)
		connection = await establishConnection();
	
	program = anchor_init(connection, wallet_provider, program_id, idl);
	
	var power = Math.pow(10, decimals);
	
	reward *= power;
	
	const escrow = anchor.web3.Keypair.generate();
	
	const transaction = await program.transaction.createcollection(
		new anchor.BN(reward),
		new anchor.BN(collection_id),
		new anchor.BN(project_id),
		{
			accounts: {
				owner: public_key,
				
				escrowAccount: escrow.publicKey,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			instructions: [
				await program.account.escrowAccount.createInstruction(escrow),
			],
		}
	);
	
	
	transaction.feePayer = new PublicKey(public_key);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	transaction.sign(escrow);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('signature', signature);
	
	return [signature, escrow.publicKey.toString()];
}

/**
 * 
 * Fetch all staking accounts
 * 
 */
export async function fetch_all_staking_accounts(wallet_provider, public_key) {
	
	if(!connection)
		connection = await establishConnection();
	
	program = anchor_init(connection, wallet_provider, program_id, idl);
	
	var staked_accounts = await program.account.escrowAccountStaking.all();
	
	var staked_accounts_for_owner = [];
	
	for(var account of staked_accounts) {
		
		if(account.account.owner.toString() == public_key)
			staked_accounts_for_owner.push(account);
	}
	
	return staked_accounts_for_owner;
}

/**
 * 
 * Fetch all staking accounts
 * 
 */
export async function fetch_all_staking_accounts_admin(wallet_provider, public_key) {
	
	if(!connection)
		connection = await establishConnection();
	
	program = anchor_init(connection, wallet_provider, program_id, idl);
	
	var staked_accounts = await program.account.escrowAccountStaking.all();
	
	var staked_accounts_for_owner = [];
	
	for(var account of staked_accounts) {
		
		staked_accounts_for_owner.push(account);
	}
	
	return staked_accounts_for_owner;
}


/**
 * 
 * Prapare Stake transaction
 * 
 */
export async function prepare_stake_transaction(wallet_provider, public_key, escrow, mint, token_address, collection_id, project_id) {
	
	if(!connection)
		connection = await establishConnection();
	
	program = anchor_init(connection, wallet_provider, program_id, idl);
	
	escrow = new PublicKey(escrow);
	mint = new PublicKey(mint);
	
	const escrow_staking = anchor.web3.Keypair.generate();
	
	var token_account_owner = await getTokenAccountForTokenAndOwner(token_address.toString(), public_key.toString());
	
	var [instruction_create_token_account, _token_account_owner] = await prepareAta(token_address, public_key, public_key);
		
	if(token_account_owner === null) {
		
		token_account_owner = _token_account_owner;
	}
	
	const instruction = await program.instruction.stake(
		new anchor.BN(collection_id),
		new anchor.BN(project_id),
		{
			accounts: {
				mint: mint,
				owner: public_key,
				tokenAccountOwner: new PublicKey(token_account_owner),
				escrowAccount: escrow,
				escrowAccountStaking: escrow_staking.publicKey,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			// instructions: [
				// await program.account.escrowAccountStaking.createInstruction(escrow_staking),
			// ],
		}
	);
	
	var transaction = new Transaction();
	
	if(instruction_create_token_account !== null)
		transaction.add(instruction_create_token_account);
	
	transaction.add(await program.account.escrowAccountStaking.createInstruction(escrow_staking))
	
	transaction.add(instruction);
	
	transaction.feePayer = new PublicKey(public_key);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	transaction.sign(escrow_staking);
	
	return transaction;
}

/**
 * 
 * Stake
 * 
 */
export async function stake(wallet_provider, public_key, escrow, mint, token_address, collection_id, project_id) {
	
	var transaction = await prepare_stake_transaction(wallet_provider, public_key, escrow, mint, token_address, collection_id, project_id);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('signature', signature);
	
	return signature;
}

/**
 * 
 * Stake all
 * 
 */
export async function stake_all(wallet_provider, public_key, nfts, token_address, project_id) {
	
	if(!connection)
		connection = await establishConnection();
	
	var transactions = [];
	
	for(var nft of nfts) {
		
		transactions.push(await prepare_stake_transaction(wallet_provider, public_key, nft.escrow, nft.nft_address, token_address, nft.collection_id, project_id));
	}
	
	var signatures = await signAndSendMultipleTransactions(wallet_provider, connection, transactions);
	
	console.log('signatures', signatures);
	
	return signatures;
}

/**
 * 
 * Claim
 * 
 */
export async function prepare_claim_transaction(wallet_provider, public_key, escrow, escrow_staking, mint, token_address, collection_id, project_id) {
	
	if(!connection)
		connection = await establishConnection();
	
	program = anchor_init(connection, wallet_provider, program_id, idl);
	
	escrow = new PublicKey(escrow);
	escrow_staking = new PublicKey(escrow_staking);
	mint = new PublicKey(mint);
	token_address = new PublicKey(token_address);
	
	var [instruction_create_token_account, token_account_owner] = await prepareAta(token_address, public_key, public_key)
	
	const vault_account_pda = await get_pda(connection, program_id, get_seeds(token_address, project_id));
	const vault_authority_pda = await get_pda(connection, program_id, get_seeds_authority(token_address, project_id));
	
	// console.log('token_address', token_address.toString(), project_id);
	// console.log('vault_account_pda', vault_account_pda.toString(), project_id);
	// console.log('vault_authority_pda', vault_authority_pda.toString(), project_id);
	
	const intruction = await program.instruction.claim(
		new anchor.BN(project_id),
		{
			accounts: {
				
				owner: public_key,
				tokenAccountOwner: new PublicKey(token_account_owner),
				mint: mint,
				escrowAccount: escrow,
				escrowAccountStaking: escrow_staking,
				tokenAddress: token_address,
				vaultAccount: vault_account_pda,
				vaultAuthority: vault_authority_pda,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
		}
	);
	
	var transaction = new Transaction;
	
	if(instruction_create_token_account !== null)
		transaction.add(instruction_create_token_account);
	
	transaction.add(intruction);
	
	transaction.feePayer = new PublicKey(public_key);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	return transaction;
}

/**
 * 
 * Claim
 * 
 */
export async function claim(wallet_provider, public_key, escrow, escrow_staking, mint, token_address, collection_id, project_id) {
	
	if(!connection)
		connection = await establishConnection();
	
	var transaction = await prepare_claim_transaction(wallet_provider, public_key, escrow, escrow_staking, mint, token_address, collection_id, project_id);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('signature', signature);
	
	return signature;
}

/**
 * 
 * Claim
 * 
 */
export async function claim_all(wallet_provider, public_key, nfts, token_address, project_id) {
	
	if(!connection)
		connection = await establishConnection();
	
	var transactions = [];
	
	for(var nft of nfts) {
		
		transactions.push(await prepare_claim_transaction(wallet_provider, public_key, nft.escrow, nft.escrow_staking, nft.nft_address, token_address, nft.collection_id, project_id));
	}
	
	var signatures = await signAndSendMultipleTransactions(wallet_provider, connection, transactions);
	
	console.log('signatures', signatures);
	
	return signatures;
}

/**
 * 
 * Prepare unstake transaction
 * 
 */
export async function prepare_unstake_transaction(wallet_provider, public_key, escrow, escrow_staking, mint, token_address, collection_id, project_id) {
	
	if(!connection)
		connection = await establishConnection();
	
	program = anchor_init(connection, wallet_provider, program_id, idl);
	
	escrow = new PublicKey(escrow);
	escrow_staking = new PublicKey(escrow_staking);
	mint = new PublicKey(mint);
	token_address = new PublicKey(token_address);
	
	var [instruction_create_token_account, token_account_owner] = await prepareAta(token_address, public_key, public_key)
	
	const vault_account_pda = await get_pda(connection, program_id, get_seeds(token_address, project_id));
	const vault_authority_pda = await get_pda(connection, program_id, get_seeds_authority(token_address, project_id));
	
	// console.log('token_address', token_address.toString(), project_id);
	// console.log('vault_account_pda', vault_account_pda.toString(), project_id);
	// console.log('vault_authority_pda', vault_authority_pda.toString(), project_id);
	
	const intruction = await program.instruction.unstake(
		new anchor.BN(project_id),
		{
			accounts: {
				
				owner: public_key,
				tokenAccountOwner: new PublicKey(token_account_owner),
				mint: mint,
				escrowAccount: escrow,
				escrowAccountStaking: escrow_staking,
				tokenAddress: token_address,
				vaultAccount: vault_account_pda,
				vaultAuthority: vault_authority_pda,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
		}
	);
	
	var transaction = new Transaction;
	
	if(instruction_create_token_account !== null)
		transaction.add(instruction_create_token_account);
	
	transaction.add(intruction);
	
	transaction.feePayer = new PublicKey(public_key);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	return transaction;
}

/**
 * 
 * Unstake
 * 
 */
export async function unstake(wallet_provider, public_key, escrow, escrow_staking, mint, token_address, collection_id, project_id) {
	
	if(!connection)
		connection = await establishConnection();
	
	var transaction = await prepare_unstake_transaction(wallet_provider, public_key, escrow, escrow_staking, mint, token_address, collection_id, project_id);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('signature', signature);
	
	return signature;
}

/**
 * 
 * Unstake all
 * 
 */
export async function unstake_all(wallet_provider, public_key, nfts, token_address, project_id) {
	
	if(!connection)
		connection = await establishConnection();
	
	var transactions = [];
	
	for(var nft of nfts) {
		
		transactions.push(await prepare_unstake_transaction(wallet_provider, public_key, nft.escrow, nft.escrow_staking, nft.nft_address, token_address, nft.collection_id, project_id));
	}
	
	var signatures = await signAndSendMultipleTransactions(wallet_provider, connection, transactions);
	
	console.log('signatures', signatures);
	
	return signatures;
}



/**
 * 
 * Prepare associated token account
 * 
 */
export async function prepareAta(mint, destination, payer) {
	
	if(!connection)
		connection = await establishConnection()
	
	mint = new PublicKey(mint)
	destination = new PublicKey(destination)
	payer = new PublicKey(payer)
	
	// we create the tokenAccount
	var myToken = new Token(
		connection,
		mint,
		TOKEN_PROGRAM_ID,
		destination
	)
	
	const associatedDestinationTokenAddr = await Token.getAssociatedTokenAddress(
		myToken.associatedProgramId,
		myToken.programId,
		mint,
		destination,  // to
		true
	)
	
	// check if it exists
	var receiverAccount = await connection.getAccountInfo(associatedDestinationTokenAddr)
	
	var instruction = null
	
	if (receiverAccount === null) {

		instruction = Token.createAssociatedTokenAccountInstruction(
			myToken.associatedProgramId,
			myToken.programId,
			mint,
			associatedDestinationTokenAddr,
			destination, // to
			payer // signer
		)
	}
	
	return [instruction, associatedDestinationTokenAddr]
}
